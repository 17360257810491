import React from "react";
import { Element } from "react-scroll";
import translate from "../../i18n/translate";
import {
  CalendarIcon,
  CashIcon,
  ChartIcon,
  ClipboardIcon,
  TicketIcon,
  FlagIcon,
} from "../common/icons";

const FeatureList = () => {
  return (
    <>
      <Element name="solutions"></Element>
      <div class="relative bg-white py-6 sm:py-6 lg:py-12">
        <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 class="text-base font-semibold tracking-wider text-indigo-600 uppercase">
            {translate("feature_list_title_one")}
          </h2>
          <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            {translate("feature_list_title_two")}
          </p>
          <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            {translate("feature_list_title_three")}
          </p>
          <div class="mt-12">
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <div class="pt-6">
                <div class="flow-root h-full bg-gray-50 rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <ClipboardIcon className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {translate("feature_list_track_title")}
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      {translate("feature_list_track_text")}
                    </p>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <div class="flow-root h-full bg-gray-50 rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <TicketIcon className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {translate("feature_list_maintenance_title")}
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      {translate("feature_list_maintenance_text")}
                    </p>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <div class="flow-root h-full bg-gray-50 rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <ChartIcon className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {translate("feature_list_insight_title")}
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      {translate("feature_list_insight_text")}
                    </p>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <div class="flow-root h-full bg-gray-50 rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <CashIcon className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {translate("feature_list_payment_title")}
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      {translate("feature_list_payment_text")}
                    </p>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <div class="flow-root h-full bg-gray-50 rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <CalendarIcon className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {translate("feature_list_reserve_title")}
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      {translate("feature_list_reserve_text")}
                    </p>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <div class="flow-root h-full bg-gray-50 rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <FlagIcon className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {translate("feature_list_listing_title")}
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      {translate("feature_list_listing_text")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureList;
