import React from "react";
import { useState } from "react";
import Recaptcha from "react-recaptcha";
import { useForm } from "react-hook-form";
import get from "lodash.get";
import translate from "../../i18n/translate";

const ContactUs = () => {
  const [captchaValide, setCaptchaValide] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const onVerifyCallback = () => {
    setCaptchaValide(true);
  };
  const onExpiredCallback = () => {
    setCaptchaValide(false);
  };
  const onSubmit = async (data, e) => {
    await fetch(`${process.env.REACT_APP_FRONTT_API_BASE_URL}/contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    setConfirmation(true);
    e.target.reset();
  };

  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
              {translate("contact_us_title")}
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              {translate("contact_us_text")}
            </p>
            <dl className="mt-8 text-base leading-6 text-gray-500">
              <div className="mt-6">
                <dt className="sr-only">
                  {translate("contact_us_phone_number")}
                </dt>
                {/* <dd className="flex">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <span className="ml-3">+1 (555) 123-4567</span>
                </dd> */}
              </div>
              <div className="mt-3">
                <dt className="sr-only">{translate("contact_us_email")}</dt>
                <dd className="flex">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="ml-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:sales@frontt.io"
                      className="ml-1"
                    >
                      sales@frontt.io
                    </a>
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            {confirmation && (
              <div className=" mb-4 rounded-md bg-green-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-green-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm leading-5 font-medium text-green-800">
                      {translate("contact_us_form_confirmation")}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <form
              className="grid grid-cols-1 row-gap-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <label for="full_name" className="sr-only">
                  {translate("contact_us_name")}
                </label>
                <div className="relative rounded-md shadow-sm">
                  <input
                    name="name"
                    className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    placeholder="Name"
                    ref={register({
                      required: true,
                    })}
                  />
                </div>
                {get(errors, "name.type") === "required" && (
                  <p className="text-red-500 text-xs">
                    {translate("contact_us_form_required")}
                  </p>
                )}
              </div>
              <div>
                <label for="email" className="sr-only">
                  {translate("contact_us_email")}
                </label>
                <div className="relative rounded-md shadow-sm">
                  <input
                    name="email"
                    className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    placeholder="Email"
                    ref={register({
                      required: true,
                      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                </div>
                {get(errors, "email.type") === "required" && (
                  <p className="text-red-500 text-xs">
                    {translate("contact_us_form_required")}
                  </p>
                )}
              </div>
              <div>
                <label for="phone" className="sr-only">
                  {translate("contact_us_phone_number")}
                </label>
                <div className="relative rounded-md shadow-sm">
                  <input
                    name="phoneNumber"
                    className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    placeholder="Phone number"
                    ref={register({
                      required: true,
                    })}
                  />
                </div>
              </div>
              <div>
                <label for="message" className="sr-only">
                  {translate("contact_us_message")}
                </label>
                <div className="relative rounded-md shadow-sm">
                  <textarea
                    name="message"
                    rows="4"
                    className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    placeholder="Message"
                    ref={register({ required: true })}
                  />
                </div>
                {get(errors, "message.type") === "required" && (
                  <p className="text-red-500 text-xs">
                    {translate("contact_us_form_required")}
                  </p>
                )}
              </div>
              <Recaptcha
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                verifyCallback={onVerifyCallback}
                expiredCallback={onExpiredCallback}
              />
              <div>
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="submit"
                    disabled={!captchaValide}
                    className={`${
                      !captchaValide ? "cursor-not-allowed opacity-50" : ""
                    } inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out`}
                  >
                    {translate("contact_us_submit")}
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
