import React from "react";
import Navigation from "./navigation";
import Footer from "./footer";

const Layout = ({ children, setLocale, locale }) => {
  return (
    <>
      <Navigation />
      {children}
      <Footer setLocale={setLocale} locale={locale} />
    </>
  );
};

export default Layout;
