import React from "react";
import CallToAction from "../common/call-to-action";
import translate from "../../i18n/translate";
import { NavLink } from "react-router-dom";

const Pricing = () => {
  return (
    <>
      <div className="relative pt-6 pb-12 lg:pb-20">
        <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:px-6 md:mt-16 lg:mt-20">
          <div className="bg-gray-100">
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                  <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                    {translate("pricing_title")}
                  </h2>
                  <p className="mt-4 text-xl leading-7 text-gray-600">
                    {translate("pricing_text")}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
              <div className="relative">
                <div className="absolute inset-0 h-1/2 bg-gray-100"></div>
                <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                    <div className="w-full bg-white px-6 py-8 lg:flex-shrink-1 lg:p-12">
                      <h3 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                        {translate("pricing_slogan_one")} <br />{" "}
                        {translate("pricing_slogan_two")}
                      </h3>
                      <p className="mt-6 text-base leading-6 text-gray-500">
                        {translate("pricing_increase_details")}
                      </p>
                      <div className="mt-8">
                        <div className="flex items-center">
                          <h4 className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
                            {translate("pricing_included")}
                          </h4>
                          <div className="flex-1 border-t-2 border-gray-200"></div>
                        </div>
                        <ul className="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                          <li className="flex items-start lg:col-span-1">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-5 w-5 text-green-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-sm leading-5 text-gray-700">
                              {translate("pricing_bullet_one")}
                            </p>
                          </li>
                          <li className=" mt-5 flex items-start lg:col-span-1 lg:mt-0">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-5 w-5 text-green-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-sm leading-5 text-gray-700">
                              {translate("pricing_bullet_five")}
                            </p>
                          </li>
                          <li className="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-5 w-5 text-green-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-sm leading-5 text-gray-700">
                              {translate("pricing_bullet_two")}
                            </p>
                          </li>
                          <li className="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-5 w-5 text-green-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-sm leading-5 text-gray-700">
                              {translate("pricing_bullet_three")}
                            </p>
                          </li>
                          <li className="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-5 w-5 text-green-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-sm leading-5 text-gray-700">
                              {translate("pricing_bullet_six")}
                            </p>
                          </li>
                          <li className="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-5 w-5 text-green-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-sm leading-5 text-gray-700">
                              {translate("pricing_bullet_seven")}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                      <p className="text-lg leading-6 font-medium text-gray-900">
                        {translate("pricing_per_unit")}
                      </p>
                      <div className="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900">
                        <span>$1</span>
                        <span className="ml-3 text-xl leading-7 font-medium text-gray-500">
                          CAD
                        </span>
                      </div>
                      <p class="mt-4 text-sm">
                        {translate("pricing_increase_details_per_month")}
                      </p>
                      <div className="mt-6">
                        {/* <div className="rounded-md shadow">
                          <a
                            href={`${process.env.REACT_APP_FRONTT_BASE_URL}/signup`}
                            className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                          >
                            {translate("pricing_get_started")}
                          </a>
                        </div> */}
                        <div className="rounded-md shadow">
                          <NavLink
                            to="/contact-us"
                            className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                          >
                            {translate("pricing_get_started")}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
    </>
  );
};

export default Pricing;
