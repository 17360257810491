import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";

import { LOCALES } from "./constants";
import translations from "./translations";

const I18nProvider = ({ children, locale = LOCALES.ENGLISH }) => (
  <IntlProvider
    textComponent={Fragment}
    locale={locale}
    messages={translations[locale]}
  >
    {children}
  </IntlProvider>
);

export default I18nProvider;
