import React, { useState } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { scroller } from "react-scroll";
import translate from "../../i18n/translate";

const promisify = async (fn) => new Promise((res, rej) => res(fn));

const Navigation = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false);

  const scrollToHandler = async () => {
    await promisify(history.push("/"));
    scroller.scrollTo("solutions", {
      smooth: true,
    });
  };

  return (
    <div className="relative bg-gray-50">
      <div className="relative bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="w-0 flex-1 flex">
              <Link to="/" className="inline-flex">
                <img
                  className="h-6 w-auto sm:h-7"
                  src="/assets/logo.svg"
                  alt="frontt"
                />
              </Link>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                onClick={() => setIsOpen(!isOpen)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10">
              <Link
                className="cursor-pointer text-gray-500 inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 transition ease-in-out duration-150"
                to="/"
                onClick={() => scrollToHandler()}
              >
                {translate("navigation_solutions")}
              </Link>

              <NavLink
                to="/pricing"
                className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 transition ease-in-out duration-150"
                activeClassName="text-gray-900"
              >
                {translate("navigation_pricing")}
              </NavLink>
              <NavLink
                to="/contact-us"
                className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 transition ease-in-out duration-150"
              >
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-blue-100 text-gray-800">
                  {translate("navigation_demo")}
                </span>
              </NavLink>
            </nav>
            <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
              {/* <a
                href={`${process.env.REACT_APP_FRONTT_BASE_URL}/login`}
                className="whitespace-no-wrap text-base leading-6 font-medium text-gray-500 hover:text-gray-900 transition ease-in-out duration-150"
              >
                {translate("navigation_signin")}
              </a> */}
              {/* <span className="inline-flex rounded-md shadow-sm">
                <a
                  href={`${process.env.REACT_APP_FRONTT_BASE_URL}/signup`}
                  className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                >
                  {translate("navigation_signup")}
                </a>
              </span> */}
            </div>
          </div>
        </div>

        <div
          className={
            "absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden " +
            (isOpen ? "block" : "hidden")
          }
        >
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5 space-y-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src="/assets/logo.svg"
                      alt="Workflow"
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      onClick={() => setIsOpen(!isOpen)}
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <nav className="grid row-gap-8">
                    <Link
                      to="/"
                      onClick={() => scrollToHandler()}
                      className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150 text-base leading-6 font-medium text-gray-900"
                    >
                      <img
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        src="/assets/solutions.svg"
                        alt="solutions"
                      />
                      {translate("navigation_solutions")}
                    </Link>
                    <Link
                      to="/pricing"
                      className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150 text-base leading-6 font-medium text-gray-900"
                    >
                      <img
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        src="/assets/price.svg"
                        alt="solutions"
                      />
                      {translate("navigation_pricing")}
                    </Link>
                    <NavLink
                      to="/contact-us"
                      className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150 text-base leading-6 font-medium text-gray-900"
                    >
                      <img
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        src="/assets/demo.svg"
                        alt="solutions"
                      />
                      {translate("navigation_demo")}
                    </NavLink>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="space-y-6">
                  {/* <span className="w-full flex rounded-md shadow-sm">
                    <a
                      href={`${process.env.REACT_APP_FRONTT_BASE_URL}/signup`}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                    >
                      {translate("navigation_signup")}
                    </a>
                  </span> */}
                  {/* <p className="text-center text-base leading-6 font-medium text-gray-500">
                    {translate("navigation_existing_customer")}{" "}
                    <a
                      href={`${process.env.REACT_APP_FRONTT_BASE_URL}/login`}
                      className="text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                    >
                      {translate("navigation_signin")}
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Navigation);
