import { LOCALES } from "../constants";

export default {
  [LOCALES.ENGLISH]: {
    navigation_solutions: "Solutions",
    navigation_pricing: "Pricing",
    navigation_demo: "Request a demo",
    navigation_signin: "Sign in",
    navigation_signup: "Sign up",
    navigation_existing_customer: "Already have an account?",
    footer_slogan:
      "We make renting simple, secure, and intuitive for landlords and renters.",
    footer_support: "Support",
    footer_demo: "Request a demo",
    footer_contact: "Contact us",
    footer_company: "Company",
    footer_about: "About",
    footer_pricing: "Pricing",
    footer_legal: "Legal",
    footer_privacy: "Privacy",
    footer_terms: "Terms",
    footer_english: "English",
    footer_french: "French",
    hero_title_main: "Every property management task",
    hero_title_secondary: "under one roof",
    hero_title_text:
      "Free your team. Enjoy effortless property management with an all-in-one platform.",
    call_to_action_title_one: "Smart landlords choose Frontt",
    call_to_action_title_two: "to take the stress out of property management.",
    call_to_action_get_started: "Get started",
    call_to_action_demo: "Request a demo",
    feature_list_title_one: "All in one place.",
    feature_list_title_two: "All in sync.",
    feature_list_title_three: "Integrations, communication, tools, and more",
    feature_list_track_title: "Keep track of important details",
    feature_list_track_text:
      "Keep tenant information, including contact info, unit number, and lease documents organized and easily accessible. Find out in advance when rent agreements need to be renewed or appliances require service.",
    feature_list_maintenance_title: "Handle maintenance and unexpected repairs",
    feature_list_maintenance_text:
      "Manage maintenance for all your units using customer portals. Tenants can request repairs from any device, adding detailed information, photos, and additional notes. Property managers can then immediately assign staff to address issues and resolve them quickly.",
    feature_list_insight_title: "Get rapid insights from your dashboards",
    feature_list_insight_text:
      "Use real-time dashboards to get a quick overview of important metrics. Track tenant complaint status, vacant properties, rent received or overdue, and much more.",
    feature_list_payment_title: "Encourage on-time rent payment",
    feature_list_payment_text:
      "Record rental payments and keep track of outstanding balances and payment histories. Landlords or property managers can schedule notifications to remind tenants that their rent is due, and automatically email invoices on payment.",
    feature_list_reserve_title: "Allow common space reservations",
    feature_list_reserve_text:
      "Using our booking feature, property managers can control which amenities are available for booking/using, hours of operation, maximum booking length, maximum spots available at one time and even how often a unit or resident can book a specific amenity.",
    feature_list_listing_title: "Effortlessly list your property",
    feature_list_listing_text:
      "List your vacant units with just on click and Frontt will take care of everything else: posting them, updating them if you change some details, and removing them once the unit is filled.",
    feature_details_title_one: "Give your tenants peace of mind",
    feature_details_title_two:
      "Reclaim your time and money with Frontt’s simple tools.",
    feature_details_rent_title: "Never worry about rent",
    feature_details_rent_text:
      "Collect rent, share leases and other documents, and bill automatic late fees for free. When you collect rent for free through Frontt, the money goes straight to your bank account—no worries, no hassle, no cost.",
    feature_details_rent_bullet_one: "Online rent collection",
    feature_details_rent_bullet_two: "See all payments in one place",
    feature_details_assets_title: "Keep your assets in shape",
    feature_details_assets_text:
      "Tenants, Rental Owners, you and your employees, all can submit maintenance tickets, share images and discuss the issue.",
    feature_details_asset_bullet_one: "Manage Maintenance tickets",
    feature_details_asset_bullet_two:
      "Update your tenants on their maintenance status",
    feature_details_asset_bullet_three: "Discuss issues with your tenants",
    feature_details_marketing_title: "List your rental property",
    feature_details_marketing_text:
      "By listing your property with Frontt, you will reach millions of ready-to-move renters across the web in search of a new home. Your property will be automatically placed on the most popular rental websites in the rendboard.ca network, reaching millions of renters.",
    feature_details_marketing_bullet_one:
      "Once you add your property you can immediately list it so that renters start seeing it. All that’s left to do is sit back and watch the leads come in",
    feature_details_marketing_bullet_two:
      "Reach millions of ready-to-move renters",
    contact_us_title: "Get in touch",
    contact_us_text:
      "Our team of support specialists is made up of friendly humans who work at our headquarters in Montreal, Quebec.",
    contact_us_phone_number: "Phone number",
    contact_us_email: "Email",
    contact_us_message: "Message",
    contact_us_submit: "Submit",
    contact_us_name: "Full name",
    contact_us_form_required: "This field is required",
    contact_us_form_confirmation:
      "Thank you for getting in touch! One of our colleagues will get to you as soon as possible!",
    about_title:
      "We make renting simple, secure, and intuitive for landlords and renters.",
    about_text:
      "Renting has been fragmented, unsecure, and unnecessarily complex—even though it affects nearly everyone at some point in their lives. By providing simple, powerful tools for landlords, property managers and renters, Frontt is changing the way renting works.",
    about_comany_title: "Our company",
    about_comany_text:
      "We believe in a world where owning and managing a home should be easy for everyone.",
    about_who_title: "Who we are",
    about_who_text:
      "Our core mission is to solve some of the biggest challenges that property owners, landlords and property managers face by making complicated and tedious tasks both simple and personal.",
    about_value_title: "What we value",
    about_value_text:
      "Taking ownership, building trust and cultivating belonging are just a few of our core values. These values give us a sense of purpose and help up work towards our common goals. They inspire us to be better, individually and collectively.",
    pricing_title: "Everything Frontt offers",
    pricing_text: "Pricing that scales with your business.",
    pricing_slogan_one: "A lot of companies charge more for these.",
    pricing_slogan_two: "We do not.",
    pricing_increase_details:
      "Price increase is only $1 per additional unit; Data migration for free.",
    pricing_increase_details_per_month: "per unit per month",
    pricing_included: "What's included",
    pricing_bullet_one: "Collect rent online",
    pricing_bullet_two: "Track maintenance requests",
    pricing_bullet_three: "Upload and share important documents",
    pricing_bullet_five: "Unlimited cloud storage",
    pricing_bullet_six: "List your rental property",
    pricing_bullet_seven: "Amenity booking",
    pricing_per_unit: "Starting at",
    pricing_policy: "Learn about our membership policy",
    pricing_get_started: "Get started",
  },
};
