import React from "react";
import CallToAction from "../common/call-to-action";
import translate from "../../i18n/translate";

const About = () => {
  return (
    <>
      <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
        <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <div className="md:flex md:items-center md:justify-center">
              <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                {translate("about_title")}
              </h2>
            </div>
            <p className="mt-8">
              <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                <p>{translate("about_text")}</p>
              </div>
            </p>
          </div>
        </div>
      </section>
      <div className="py-12 bg-white">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  {translate("about_comany_title")}
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  {translate("about_comany_text")}
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  {translate("about_who_title")}
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  {translate("about_who_text")}
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  {translate("about_value_title")}
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  {translate("about_value_text")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
    </>
  );
};

export default About;
