import React from "react";

const Terms = () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto mb-6">
          <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Terms and conditions
          </h1>
        </div>
        <div className="prose prose-lg text-gray-500 mx-auto">
          <p>
            The following terms of service (the “Terms of Service”) govern your
            use of this website (the “Website”) provided by Frontt Digital Inc.
            (collectively in these Terms of Service, “Frontt”, “we”, or “us”).
            This Website (or any other designated or linked website or web
            pages) is owned and operated by Frontt. You signify your acceptance
            of these Terms of Service by accessing or using this Website. If, at
            any time, you do not wish to accept these Terms of Service, you
            should not use the Website. Frontt reserves the right to change or
            modify any of the terms and conditions in these Terms of Service
            from time to time, any time, without notice, and in its sole
            discretion. Any changes or modifications to these Terms of Service
            will be effective upon posting of the changes. Unless explicitly
            stated otherwise, any new features, which enhance or augment the
            Website shall be subject to the Terms of Service. Your continued use
            of the Website following the posting of any changes or modifications
            constitutes your acceptance of such changes or modifications, and if
            you do not agree with these changes or modifications, you should
            cease using the Website. You are responsible for regularly reviewing
            the most current Terms of Service which are available on the
            Website. These
          </p>
          <p className="mt-2">
            Terms of Service were last updated on October 1, 2020.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            1. Intellectual Property Rights
          </h1>
          <p>
            Unless otherwise stated, Frontt (and its licensors, where
            applicable) owns all intellectual property rights in the Website,
            including, without limitation, the underlying HTML, software, text,
            images, video and audio content and service marks thereon
            (collectively, the “Content”), or has obtained the permission of
            other owners of intellectual property in the Content to post the
            Content on the Website. All Content is protected by, among others,
            the Canadian Copyright Act, or by provisions prescribed by any other
            law, in Canada and abroad.
            <br />
            You agree to abide by all applicable patent, trademark, copyright
            and other laws, as well as any additional patent, trademark and
            copyright notices or restrictions on the Website. Any use of
            materials on the Website other than as permitted in these Terms of
            Service, including reproduction, modification, distribution, or
            republication, without prior written permission of Frontt is
            absolutely prohibited. Nothing stated herein shall be deemed to
            constitute a sale or other conveyance to you of any ownership rights
            in or related to the Content or the Website.
            <br />
            The “Frontt” business name, the Frontt logo and other trademark
            and/or service marks displayed on the Website are the exclusive
            property of Frontt, and are protected by applicable patent,
            trademark, copyright and other laws, and may not be used for any of
            the aforementioned purposes. Frontt may protect the Website by
            technological means intended to prevent unauthorized use of the
            Website. You undertake not to circumvent these means. Infringement
            of the rights in and to the Website will, in and of itself, result
            in the termination of all your rights under these Terms of Service.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            2. External Links
          </h1>
          <p>
            You understand that certain Content on the Website may include
            materials or information from third parties and links to other third
            party websites. Frontt does not control those third party websites,
            and these Terms of Service do not apply to websites that Frontt does
            not own or control, or to the actions of people that Frontt does not
            employ or manage. In no event shall Frontt or its licensors be
            responsible for any content, products, or other materials on or
            available from such websites.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            3. License and Restrictions
          </h1>
          <p>
            You agree to use the Website for lawful purposes only and in a way
            that does not prejudice the rights or does not restrict or hinder
            the use of the Website by any person. You may not use the Website
            for any illegal purpose or in any manner inconsistent with these
            Terms of Service. You may not, without limitation: (i) duplicate the
            Website, or any of the materials and/or Content contained therein;
            (ii) create derivative works based on the Website or any of the
            materials and/or Content contained therein or integrate it within a
            service of your own; (iii) use the Website or any of the materials
            and/or Content contained therein for any commercial purpose, or for
            any public display, public performance, sale or rental; (iv)
            distribute the Website or any of the materials and/or Content
            contained therein, except as specifically intended for in the
            Website; (v) remove any copyright or other proprietary notices from
            the Website and/or Content contained therein; (vi) circumvent any
            encryption or other security tools used anywhere on the Website
            (including the theft of user names and passwords or using another
            person’s user name and password); (vii) use any data mining, robots
            or similar data gathering and extraction tools on the Website;
            (viii) decompile, reverse engineer, modify or disassemble any of the
            software aspect of the Website or Content; or (ix) sell, rent,
            lease, license, sublicense, transfer, distribute, re-transmit or
            otherwise assign to any third party the materials and/or Content or
            any of your rights to access and use the materials and/or Content as
            granted above. You agree to cooperate with Frontt in causing any
            unauthorized use to cease immediately. You acknowledge, consent and
            agree that Frontt may access, preserve, and disclose your content if
            required to do so by law or in a good faith belief that such access,
            preservation or disclosure is reasonably necessary to: (i) comply
            with legal process; (ii) enforce these Terms of Service; (iii)
            respond to claims that any material violates the rights of third
            parties; (iv) respond to your requests for customer service; or (v)
            protect the rights, property or personal safety of Frontt, the
            Website, its users and the public.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            4. Your Content
          </h1>
          <p>
            You are solely responsible for all content that you upload, email,
            post or otherwise transmit via or to the Website, including
            documents, text, graphics, video, messages, forum postings,
            comments, questions, or other materials (collectively, the “User
            Content”). Frontt reserves the right to use, store, and transfer
            User Content that is collected, posted or uploaded to the Website by
            its users. Whenever you submit User Content to the Website, app, or
            webportal, you agree that you are granting us a worldwide,
            perpetual, irrevocable, royalty-free, non-exclusive, sublicensable
            and transferable right and license to use your User Content. You
            also hereby represent and warrant that you have the right to grant
            us this right over your User Content, and that you will indemnify us
            for any loss resulting from a breach of this warranty and defend us
            against claims resulting from same.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            5. Disclaimer
          </h1>
          <p>
            FRONTT PROVIDES THE WEBSITE AND ITS CONTENT, MATERIALS, INFORMATION,
            SOFTWARE AND PRODUCTS INCLUDED THEREIN INCLUDING SEPARATE SERVICES
            PERFORMED BY THIRD PARTIES UNDER CONTRACT FOR USE ON AN “AS IS”,
            “WHERE IS” AND “AS AVAILABLE” BASIS. TO THE MAXIMUM EXTENT PERMITTED
            BY LAW, FRONTT DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES WHETHER
            EXPRESS OR IMPLIED, WITH RESPECT TO THE WEBSITE, INCLUDING WITHOUT
            LIMITATION, ANY WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE, FEATURES, QUALITY, NON-INFRINGEMENT, TITLE,
            PERFORMANCE, COMPATIBILITY, SECURITY OR ACCURACY. Frontt does not
            warrant that the Website will operate in an uninterrupted or
            error-free manner, or that the Website will always be available or
            free from all harmful components, or that it is safe, secured from
            unauthorized access, immune from damages, free of malfunctions, bugs
            or failures, including, but not limited to hardware failures,
            originating either from Frontt or its providers. Frontt reserves the
            right to upgrade or modify the Website with or without notice to you
            and you accept that such changes may result in you being unable to
            access the Website. Frontt reserves the right to temporarily suspend
            or interrupt the Website for periodic maintenance. Certain Content
            on the Website is provided to Frontt by third parties (“Content
            Providers”). You must conduct your own due diligence in respect of
            all Content on the Website. Content Providers disclaim all
            representations and warranties as to the accuracy of the Content and
            assume no liability or responsibility for any errors, omissions, or
            inaccuracies in the Content. Content Providers may update and/or
            modify the Content at any time and without notice to you. IN NO
            EVENT WILL Frontt , ITS OFFICERS, DIRECTORS, SHAREHOLDERS,
            EMPLOYEES, SUPPLIERS, CONTENT PROVIDERS, SUB-CONTRACTORS AND AGENTS
            BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR
            ANY OTHER DAMAGE OR LOSS (INCLUDING LOSS OF PROFIT AND LOSS OF
            DATA), COSTS, EXPENSES AND PAYMENTS, ARISING FROM OR IN CONNECTION
            WITH THE USE OF, OR THE INABILITY TO USE THE WEBSITE, OR FROM ANY
            FAULT, OR ERROR MADE BY OUR EMPLOYEES OR ANYONE ACTING ON OUR
            BEHALF, OR FROM YOUR RELIANCE ON THE CONTENT OF THE WEBSITE, OR FROM
            THE RETENTION, DELETION, DISCLOSURE AND ANY OTHER USE OR LOSS OF
            YOUR CONTENT ON THE WEBSITE. YOU UNDERSTAND AND AGREE THAT YOU
            OBTAIN OR OTHERWISE DOWNLOAD CONTENT, MATERIAL OR DATA THROUGH OR
            FOR THE USE OF THE WEBSITE AT YOUR OWN DISCRETION AND RISK AND THAT
            YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR COMPUTER
            SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH
            MATERIAL OR DATA. IN ADDITION, FRONTT DOES NOT REPRESENT OR WARRANT
            THAT THE INFORMATION ACCESSIBLE VIA THE WEBSITE IS ACCURATE,
            COMPLETE OR UP TO DATE. Your use of the Website may be subject to
            various local, provincial, state, national, or international laws,
            and you may have rights which vary from jurisdiction to
            jurisdiction. Because some countries do not allow limitations on
            warranties and/or the exclusion or limitation of damages, the
            limitations and/or exclusions found herein may not apply to you. Any
            provision found herein shall not be enforceable solely to the extent
            that it is prohibited by an applicable law.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            6. Indemnity
          </h1>
          <p>
            You agree to release, defend, indemnify and hold harmless Frontt
            (and its officers, directors, agents, subsidiaries, joint ventures,
            employees, Content Providers, and third-party service providers),
            from all claims, demands, losses, liabilities, costs, expenses,
            obligations and damages of every kind and nature, known and unknown,
            including reasonable legal fees, arising out of the following: (i)
            your use of and access to the Website; (ii) your violation of any
            term of these Terms of Service; (iii) your violation of any law or
            the rights of a third party (including, without limitation, any
            copyright, property or privacy right); or (iv) any claim that any
            content you submitted caused damage to a third party. You agree to
            notify Frontt promptly and in writing of such claim. This
            indemnification obligation will survive the termination of these
            Terms of Service and your use of the Website.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            7. General Conditions
          </h1>
          <p>
            By submitting comments, feedback, bug reports, or product
            suggestions to us, you acknowledge and agree that Frontt is free to
            use any ideas, concepts, know-how or techniques that you provide for
            any purpose without any compensation to you. You must not modify,
            adapt or hack the Website or modify another website so as to falsely
            imply that it is associated with the Website or with Frontt . You
            agree not to reproduce, duplicate, copy, sell, resell or exploit any
            portion of the Website, use of the Website, or access to the Website
            without our express written permission. You understand that the
            technical processing and transmission of the Website including your
            content, may be transferred unencrypted and involves (i)
            transmissions over various networks; and (ii) changes to conform and
            adapt to technical requirements of connecting networks or devices.
            You must not upload, post, host, or transmit unsolicited email,
            SMSs, or “spam” messages. You must not transmit any worms or viruses
            or any code of a destructive nature. The failure of Frontt to
            exercise or enforce any right or provision of these Terms of Service
            shall not constitute a waiver of such right or provision. If any of
            the provisions contained in these Terms of Service are determined to
            be void, invalid or otherwise unenforceable by a court of competent
            jurisdiction, that provision shall be enforced to the maximum extent
            permissible so as to effect the intent of these Terms of Service and
            such determination shall not affect the remaining provisions
            contained herein. The captions and headings appearing in these Terms
            of Service are for reference only and will not be used in construing
            this agreement. You may not assign these Terms of Service or any of
            your rights or obligations under these Terms of Service to a third
            party. Subject to the foregoing, these Terms of Service shall enure
            to the benefit of and be binding upon you and Frontt , and the
            parties’ respective successors and assigns. These Terms of Service
            are governed by and construed in accordance with the laws of the
            Province of Quebec and the laws of Canada applicable therein,
            without regard to the conflicts of laws provisions thereof. The
            parties attorn to the jurisdiction of the courts of the district of
            Montreal, Province of Quebec. It is the express wish of the parties
            that these Terms of Service and all related documents be drawn up in
            English. C’est la volonté expresse des parties que cette convention
            ainsi que tous les documents qui s’y rattachent soient rédigés en
            anglais. BY ACCESSING AND USING THE WEBSITE, YOU ACKNOWLEDGE THAT
            YOU HAVE READ, UNDERSTOOD AND AGREE TO BE LEGALLY BOUND BY THESE
            TERMS OF SERVICE.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
