import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./components/layout/layout";
import Home from "./components/home/home";
import Pricing from "./components/pricing/pricing";
import ContactUs from "./components/contact-us/contact-us";
import About from "./components/about/about";
import ScrollToTop from "./hooks/scroll-to-top.hook";
import { I18nPropvider, LOCALES } from "./i18n";
import Privacy from "./components/legal/privacy";
import Terms from "./components/legal/terms";

const App = () => {
  const [locale, setLocale] = useState(LOCALES.ENGLISH);

  const setLocaleHandler = (lang) => {
    setLocale(lang);
    localStorage.setItem("lang", lang);
  };

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      setLocale(localStorage.getItem("lang"));
    }
  }, [setLocale]);

  return (
    <>
      <Router>
        <ScrollToTop>
          <Switch>
            <I18nPropvider locale={locale}>
              <Layout setLocale={setLocaleHandler} locale={locale}>
                <Route exact path="/pricing">
                  <Pricing />
                </Route>
                <Route exact path="/contact-us">
                  <ContactUs />
                </Route>
                <Route exact path="/about">
                  <About />
                </Route>
                <Route exact path="/terms">
                  <Terms />
                </Route>
                <Route exact path="/privacy">
                  <Privacy />
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
              </Layout>
            </I18nPropvider>
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
};

export default App;
