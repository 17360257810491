import React from "react";

const Privacy = () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto mb-6">
          <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Privacy policy
          </h1>
        </div>
        <div className="prose prose-lg text-gray-500 mx-auto">
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            Privacy Statement
          </h1>
          <p>
            This Privacy Statement applies to Frontt Digital Inc. and its
            affiliates (collectively in this Privacy Statement, “Frontt”, “we”,
            “us”) and explains how we collect your information via this website
            (the “Website”), how we use it and the circumstances under which we
            can share it with others. Frontt agrees not to collect, use or share
            your personal information other than in accordance with this Privacy
            Statement. This Privacy Statement forms part of our Terms of
            Service. By using this Website, you agree to the terms of this
            Privacy Statement and you consent to the collection, retention,
            disclosure and use of your information by Frontt in the manner
            described herein. Your continued use of the Website following
            posting of any changes or modifications constitutes your acceptance
            of such changes or modifications and if you do not agree with these
            changes or modifications, you should immediately cease using the
            Website. You are responsible for regularly reviewing the most
            current version of the Privacy Statement which is available on our
            Website.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            Information Collection
          </h1>
          <p>
            Information that may be used to identify you as an individual, such
            as your name, age, phone number, email address, a home, postal, or
            other physical address, financial information such as your credit
            card or debit card information, or other information we may receive
            relating to communications you send us is considered personal
            information (collectively, the “Personal Information”). You may be
            asked to provide Personal Information when you register for an
            account with us. The only Personal Information we will collect and
            retain is the Personal Information you voluntarily provide us with.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            Reasons for collecting information
          </h1>
          <p className="mb-2">
            Frontt may collect and use your Personal Information for the
            following purposes:
          </p>
          <ul>
            <li>
              To operate the services and/or customize your experience with the
              services offered by Frontt (the “Services”);
            </li>
            <li>
              To provide customer support, manage requests, complaints and
              feedback;
            </li>
            <li>
              To send you notices, updates, advertisements, surveys and any
              additional information concerning the Services;
            </li>
            <li>
              To prevent fraud, misappropriation, offences, identify theft,
              misuse of the Services or any other illegal activity;
            </li>
            <li>
              To fix any breakdown or system failures concerning the Services;
            </li>
            <li>To settle disputes or assist with any investigations; and</li>
            <li>
              To ensure compliance with this Privacy Statement and our Terms of
              Service.
            </li>
          </ul>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            How your Personal Information is shared
          </h1>
          <p className="mb-2">
            We will not share your Personal Information with others without your
            permission, except under the following circumstances:
          </p>
          <ul>
            <li>
              When we need to send the information in response to inquiries by
              rights owners in connection with allegations of copyright
              infringement or other legal rights arising from the information
              you have provided us with;
            </li>
            <li>
              If we need to disclosure the information to any entity resulting
              from the sale, merger or change of control of Frontt (or Frontt
              Digital Inc.), in whole or in part, if the parties reach an
              agreement under which the collection, use, and sharing of
              information is limited to the objectives of the commercial
              transaction;
            </li>
            <li>
              If we need to share the information with entities who provide
              services, such as landlords and their representatives;
            </li>
            <li>
              If we need to share the information with third parties where the
              Services we are providing to you require us to disclose your
              Personal Information to them; and
            </li>
            <li>
              If required or necessary to do so by law or court order, in
              connection with judicial or other legal proceedings served on
              Frontt, to exercise our legal rights or defend against legal
              claims, to enforce our license terms and conditions, or in urgent
              circumstances, to protect personal safety, the public or our
              Website.
            </li>
          </ul>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            Third party sites
          </h1>
          <p>
            Our Website may contain links to other websites not operated by
            Frontt. By clicking on these links, you are leaving our Website.
            Frontt does not share your Personal Information with those websites
            and has no control over the operation of these websites. Frontt
            assumes no liability regarding their use or their privacy policies.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            Minors
          </h1>
          <p>
            This Website is directed for use by adults. We do not knowingly or
            intentionally collect Personal Information from minors under the age
            of 13. If Frontt becomes aware that a person under the age of 13 has
            submitted personally identifiable information, Frontt will reject
            said submission and will take such steps to remove such information
            from its records.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            Non-personal information
          </h1>
          <p>
            When you visit the Website we may collect information that is not
            personal in nature, including your IP address, your browser, or your
            operating system. In addition, Frontt also uses a standard
            technology called “cookies” to collect information about how you use
            the Website. This data is used to help us analyze overall usage of
            the Website. This data also (i) allows your computer and our Website
            to speak to each other in a more efficient manner, (ii) allows you
            to navigate our Website, (iii) allows us to authenticate you as the
            correct user, (iv) allows us to recognize your computer on return
            visits and (v) helps us study traffic patterns on our Website in
            order to improve the site and the services we provide. The cookie
            will only contain information that your computer is set to provide
            to the Website server. You can choose to refuse cookies at any time
            by changing your browser settings. If you choose to refuse cookies,
            this may affect your ability to use the Website.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            Changes to this Privacy Statement
          </h1>
          <p>
            We may update this Privacy Statement from time to time. In the event
            there are significant changes in the way we treat your Personal
            Information, we will post a prominent notice on the Website.
          </p>
          <h1 className="mt-2 mb-4  tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            Questions or Comments
          </h1>
          <p className="mb-2">
            If you have any questions about this Privacy Statement, please email
            us at support@frontt.io or contact us at:
          </p>
          <p>
            Frontt Digital Inc.
            <br />
            Attn: Privacy Officer
            <br />
            7-8350 rue de londres
            <br />
            Brossard, QC, Canada
            <br />
            J4Y0N1
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
