import React from "react";
import FeatureList from "./feature-list";
import FeatureDetails from "./feature-details";
import CallToAction from "../common/call-to-action";
import Hero from "./hero";

const Home = () => {
  return (
    <>
      <Hero />
      <FeatureList />
      <FeatureDetails />
      <CallToAction />
    </>
  );
};

export default Home;
