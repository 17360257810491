import React from "react";
import { Link, NavLink } from "react-router-dom";
import { LOCALES } from "../../i18n";
import translate from "../../i18n/translate";

const Footer = ({ setLocale, locale }) => {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-2 xl:gap-8">
          <div className="xl:col-span-1">
            <img className="h-7" src="/assets/logo.svg" alt="frontt" />
            <p className="mt-8 text-gray-500 text-base leading-6">
              {translate("footer_slogan")}
            </p>
          </div>
          <div className="mt-12 grid grid-cols-1 gap-8 xl:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-8">
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  {translate("footer_support")}
                </h4>
                <ul className="mt-4">
                  <li>
                    <NavLink
                      to="/contact-us"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      {translate("footer_demo")}
                    </NavLink>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/contact-us"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      {translate("footer_contact")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  {translate("footer_company")}
                </h4>
                <ul className="mt-4">
                  <li>
                    <Link
                      to="/about"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      {translate("footer_about")}
                    </Link>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/pricing"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      {translate("footer_pricing")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  {translate("footer_legal")}
                </h4>
                <ul className="mt-4">
                  <li className="mt-4">
                    <Link
                      to="/privacy"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      {translate("footer_privacy")}
                    </Link>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/terms"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      {translate("footer_terms")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:flex sm:flex-col lg:flex-row lg:justify-between lg:items-center mt-12 border-t border-gray-200 pt-8">
          <div>
            <p className="text-base leading-6 text-gray-400 xl:text-center">
              &copy; Copyright 2020 frontt.io All rights reserved
            </p>
          </div>
          <div>
            <select
              onChange={(e) => setLocale(e.target.value)}
              id="location"
              className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 sm:text-sm sm:leading-5 border-transparent"
            >
              <option
                value={LOCALES.ENGLISH}
                selected={locale === LOCALES.ENGLISH}
              >
                English
              </option>
              <option
                value={LOCALES.FRENCH}
                selected={locale === LOCALES.FRENCH}
              >
                French
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
