import { LOCALES } from "../constants";

export default {
  [LOCALES.FRENCH]: {
    navigation_solutions: "Solutions",
    navigation_pricing: "Prix",
    navigation_demo: "Demandez une démo",
    navigation_signin: "Se connecter",
    navigation_signup: "S'enregistrer",
    navigation_existing_customer: "Vous avez déjà un compte?",
    footer_slogan:
      "Nous rendons la location simple, sécurisée et intuitive pour les propriétaires et les locataires.",
    footer_support: "Support",
    footer_demo: "Demandez une démo",
    footer_contact: "Contactez nous",
    footer_company: "Entreprise",
    footer_about: "À propos",
    footer_pricing: "Prix",
    footer_legal: "Légal",
    footer_privacy: "Confidentialité",
    footer_terms: "Conditions",
    footer_english: "Anglais",
    footer_french: "Français",
    hero_title_main: "Toute tâche de gestion immobilière",
    hero_title_secondary: "sous un même toit",
    hero_title_text:
      "Libérez votre équipe. Profitez d’une gestion immobilière sans effort avec une plateforme tout-en-un.",
    call_to_action_title_one:
      "Les gestionnaires intelligents choisissent Frontt",
    call_to_action_title_two:
      "pour éliminer le stress de la gestion immobilière.",
    call_to_action_get_started: "Commencez",
    call_to_action_demo: "Demandez une démo",
    feature_list_title_one: "Tout au même endroit",
    feature_list_title_two: "Tout synchro",
    feature_list_title_three: "Intégrations, communication, outils, et plus",
    feature_list_track_title: "Suivre les détails importants",
    feature_list_track_text:
      "Gardez les renseignements sur les locataires, y compris les coordonnées, le numéro d’unité et les documents de location organisés et facilement accessibles. Informez-vous à l’avance lorsque des contrats de location doivent être renouvelés ou que des appareils nécessitent un service.",
    feature_list_maintenance_title:
      "Gérer l’entretien et les réparations imprévues",
    feature_list_maintenance_text:
      "Gérez la maintenance de toutes vos unités à l’aide des portails clients. Les locataires peuvent demander des réparations à partir de n’importe quel appareil, en ajoutant des informations détaillées, des photos et des notes supplémentaires. Les gestionnaires immobiliers peuvent alors affecter immédiatement du personnel pour régler les problèmes et les résoudre rapidement.",
    feature_list_insight_title:
      "Obtenez des informations rapides à partir de vos tableaux de bord",
    feature_list_insight_text:
      "Utilisez des tableaux de bord en temps réel pour obtenir un aperçu rapide des mesures importantes. Faites le suivi des plaintes des locataires, des propriétés vacantes, des loyers reçus ou en retard, et bien plus encore.",
    feature_list_payment_title: "Encourager le paiement du loyer à temps",
    feature_list_payment_text:
      "Suivez les paiement des loyers et gardez à l'oeil les balances de comptes et de l'historique de paiement. Les propriétaires ou les gestionnaires immobiliers peuvent programmer des notifications pour rappeler aux locataires que leur loyer est dû et envoyer automatiquement des factures par courriel sur paiement.",
    feature_list_reserve_title: "Permettez les réservations d'espaces communs",
    feature_list_reserve_text:
      "Les fonctionnalités de réservation de Frontt permettent à vos résidents de reserver des plages horaires pour vos espaces communs. À travers notre système, les gestionnaires d'immeuble peuvent contrôler les permissions d'accès aux parties communes, les heures d'ouverture, la durée et fréquence maximale permise par personne, ainsi que le nombre maximum de personnes par réservation. Les résidents peuvent pratiquement gérez leurs réservations eux-mêmes.",
    feature_list_listing_title: "Listez facilement votre propriété",
    feature_list_listing_text:
      "Listez vos unités vacantes en un seul clic et Frontt se chargera de tout le reste: les afficher, les mettre à jour si vous modifiez certains détails et les supprimer une fois l'unité remplie.",
    feature_details_title_one:
      "Offrez à vos locataires la tranquillité d’esprit",
    feature_details_title_two:
      "Sauvez votre temps et votre argent avec les outils simples de Frontt.",
    feature_details_rent_title: "Ne vous inquiétez jamais du loyer",
    feature_details_rent_text:
      "Percevez le loyer, partagez les baux et d’autres documents, et facturez automatiquement les frais de retard gratuitement.",
    feature_details_rent_bullet_one: "Collectez le loyer en ligne",
    feature_details_rent_bullet_two:
      "Affichez tous les paiements au même endroit",
    feature_details_assets_title: "Gardez vos actifs en forme",
    feature_details_assets_text:
      "Les locataires, les propriétaires, vous et vos employés pouvez tous soumettre des demandes de maintenance, partager des images et discuter du problème.",
    feature_details_asset_bullet_one: "Gérez les demandes de maintenance",
    feature_details_asset_bullet_two:
      "Tenez à jour vos locataires de l’état de leur maintenance",
    feature_details_asset_bullet_three:
      "Discutez des problèmes avec vos locataires",
    feature_details_marketing_title: "Listez votre propriété locative",
    feature_details_marketing_text:
      "En inscrivant votre propriété sur Frontt, vous atteindrez des millions de locataires prêts à déménager dans un nouveau logement. Votre propriété sera automatiquement placée sur les sites de location les plus populaires du réseau rendboard.ca, rejoignant des millions de locataires.",
    feature_details_marketing_bullet_one:
      "Une fois que vous avez ajouté votre propriété, vous pouvez immédiatement la lister afin que les locataires commencent à la voir. Il ne vous reste plus qu'à vous asseoir et à regarder les prospects entrer",
    feature_details_marketing_bullet_two:
      "Contactez des millions de locataires prêts à déménager",
    contact_us_title: "Entrer en contact",
    contact_us_text:
      "Notre équipe de spécialistes du support est composée d’humains amicaux qui travaillent à notre siège social à Montréal, au Québec.",
    contact_us_phone_number: "Numéro de téléphone",
    contact_us_email: "Email",
    contact_us_message: "Message",
    contact_us_submit: "Envoyer",
    contact_us_name: "Nom complet",
    contact_us_form_required: "Ce champ est requis",
    contact_us_form_confirmation:
      "Merci de rester en contact! Un de nos collègues vous contactera dans les plus brefs délais!",
    about_title:
      "Nous rendons la location simple, sécurisée et intuitive pour les propriétaires et les locataires.",
    about_text:
      "La location a été fragmentée, non sécurisée et inutilement complexe, même si elle touche presque tout le monde à un moment donné de leur vie. En fournissant des outils simples et puissants pour les propriétaires, les gestionnaires immobiliers et les locataires, Frontt change la façon dont la location fonctionne.",
    about_comany_title: "Notre companie",
    about_comany_text:
      "Nous croyons en un monde où il devrait être facile de posséder et de gérer une maison pour tout le monde.",
    about_who_title: "Qui sommes nous",
    about_who_text:
      "Notre mission principale est de résoudre certains des plus grands défis que  les propriétaires et les gestionnaires immobiliers font face en rendant les tâches compliquées et fastidieuses à la fois simples et personnelles.",
    about_value_title: "Ce que nous valorisons",
    about_value_text:
      "S’approprier, bâtir la confiance et cultiver l’appartenance ne sont que quelques-unes de nos valeurs fondamentales. Ces valeurs nous donnent un but et nous aident à atteindre nos objectifs communs. Elles nous incitent à être meilleurs, individuellement et collectivement.",
    pricing_title: "Toutes les offres Frontt",
    pricing_text: "Une tarification à l’échelle de votre entreprise.",
    pricing_slogan_one: "Beaucoup d’entreprises facturent plus pour cela.",
    pricing_slogan_two: "Pas nous.",
    pricing_increase_details:
      "L’augmentation du prix n’est que de 1 $ par unité supplémentaire; la migration des données est gratuite.",
    pricing_increase_details_per_month: "par unité par mois",
    pricing_included: "Ce qui est inclus",
    pricing_bullet_one: "Percevoir le loyer en ligne",
    pricing_bullet_two: "Suivre les demandes de maintenance",
    pricing_bullet_three: "Télécharger et partager des documents importants",
    pricing_bullet_five: "Stockage cloud illimité",
    pricing_bullet_six: "Listez votre propriété locative",
    pricing_bullet_seven: "Permettez les réservations d'espaces communs",
    pricing_per_unit: "À partir de",
    pricing_policy: "Découvrez notre politique d’adhésion",
    pricing_get_started: "Commencez",
  },
};
