import React from "react";
import translate from "../../i18n/translate";

const Hero = () => {
  return (
    <>
      <div className="bg-gray-50">
        <div className="relative overflow-hidden">
          <div className="relative pt-6 pb-12 lg:pb-20">
            <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:px-6 md:mt-16 lg:mt-20">
              <div className="text-center">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                  {translate("hero_title_main")}
                  <br />
                  <span className="text-indigo-600">
                    {translate("hero_title_secondary")}
                  </span>
                </h2>
                <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                  {translate("hero_title_text")}
                </p>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
              <img
                className="relative rounded-lg"
                src="/assets/hero-image.png"
                alt="App screenshot"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
